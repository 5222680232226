.container {
  color: var(--color-primary);
  text-align: center;
  padding: 12px 0;
  font-size: 12px;
  line-height: 1;

  p {
    margin-bottom: 10px;
  }

  a {
    color: var(--color-primary);
  }
}
