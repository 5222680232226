.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: 960px;
  overflow: auto;
  background: #fcfeff;
  min-height: 100vh;
}

.header {
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
}

.reducing,
.creation {
  position: relative;
  color: #fff;
  line-height: 1;

  h3,
  h4 {
    color: #fff;
  }

  p {
    margin-bottom: 6px;
    font-size: 14px;
  }

  h4 {
    margin-top: 20px;
    font-size: 30px;
    font-weight: 400;
  }

  h3 {
    font-size: 28px;
    margin-bottom: 40px;
  }

  .inner {
    position: absolute;
    left: 50%;
    top: 60%;
    width: 700px;
    transform: translate(-50%, -70%);
    display: flex;
    flex-direction: column;

    a {
      background: #DFA128;
      color: #fff;
      height: 34px;
      padding: 8px 20px;
      border-radius: 30px;
      margin-top: 10px;
      line-height: 1;
    }
  }
}

.reducing {
  background: url('./assets/reducing-bg.png') center/cover no-repeat;
}

.creation {
  background: url('./assets/creation-bg.png') center/cover no-repeat;
}

.carousel {
  flex: 1;
  min-height: 400px;
  overflow: hidden;

  :global {
    .slick-dots {
      padding-left: 30%;
    }
    .slick-dots li {
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 11px;
    }
    .slick-dots li button {
      width: 10px;
      height: 10px;
      background: transparent !important;
      border-radius: 11px;
    }
    .slick-dots li.slick-active {
      width: 22px;
      border-radius: 11px;
      background: #FFB82D;
    }
  }
}

.features {
  width: 900px;
  padding: 40px 0 26px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
