.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 12px;
  z-index: 999;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  background: #fff;
}

.navs {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;

  a {
    color: #666;
  }

  a + a {
    margin-left: 30px;
  }

  .active {
    color: var(--color-primary);
    font-weight: 700;
  }
}

.register-btn {
  margin-left: 10px !important;
}
